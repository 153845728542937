import mitt from "mitt";

const eventEmitter = mitt<{
  push: Array<unknown>;
}>();

window.dataLayer = window.dataLayer || [];

const originalDataLayerPush = window.dataLayer.push;

window.dataLayer.push = (...args: Array<unknown>): number => {
  const result = originalDataLayerPush.apply(window.dataLayer, args);

  eventEmitter.emit("push", args);

  return result;
};

export { eventEmitter };
