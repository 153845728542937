import type { ClSdk } from "../../ClSdk";
import type { ClRe } from "../../modules/ClRe";
import type { ClPlugin } from "../utils/ClPlugin";
import { z } from "zod";
import { ClDebug } from "../../modules/ClDebug";
import { eventEmitter } from "./dataLayerSpy";

const pushedEventNames = [
  "view_items",
  "view_item",
  "purchase",
  "add_to_cart",
  "remove_from_cart",
  "begin_checkout",
] as const;

const ItemSchema = z.custom<ClRe.Item>((value): value is ClRe.Item => {
  return (
    typeof value === "object" &&
    typeof value.item_id === "string" &&
    typeof value.item_name === "string"
  );
});

const pushedEventSchema = z.object({
  "0": z.literal("event"),
  "1": z.enum(pushedEventNames),
  "2": z
    .object({
      value: z.number().optional(),
      items: z.array(ItemSchema),
    })
    .passthrough() // We only want the required fields to be checked and let other fields be passed through.
    .transform((data) => {
      const { value, ...rest } = data;
      return {
        ...rest,
        ...(value === undefined ? {} : { revenue: value }),
      };
    }),
});

namespace ClPluginDataLayer {
  export interface Options extends ClPlugin.Options {}
}

class ClPluginDataLayer implements ClPlugin {
  private clSdk: ClSdk;
  private clDebug: ClDebug;

  constructor(options: ClPluginDataLayer.Options) {
    this.clSdk = options.clSdk;
    this.clDebug = new ClDebug({
      clSdk: this.clSdk,
      module: "ClPluginDataLayer",
    });
    this.processPushedDataLayer();
    eventEmitter.on("push", this.dataLayerListener);
  }

  private dataLayerListener = (args: Array<unknown>) => {
    const parsedResult = pushedEventSchema.safeParse(args[0]);
    this.clDebug.debug("received pushed event from dataLayer", parsedResult);
    if (!parsedResult.success) return;
    const eventName = parsedResult.data[1];
    const eventProps = parsedResult.data[2];
    this.clSdk.clRe.record(eventName, { props: eventProps });
  };

  /**
   * Go into the dataLayer and process any events that were pushed before push
   * was spied on.
   */
  private processPushedDataLayer = () => {
    const dataLayer = window.dataLayer;
    if (!dataLayer) {
      this.clDebug.debug(
        "window.dataLayer not found while processing pushed dataLayer events",
      );
      return;
    }
    dataLayer.forEach((data) => {
      const parsedResult = pushedEventSchema.safeParse(data);
      if (!parsedResult.success) return;
      this.clSdk.clRe.record(parsedResult.data[1], {
        props: parsedResult.data[2],
      });
    });
  };

  destroy() {
    eventEmitter.off("push", this.dataLayerListener);
  }
}

const clPluginDataLayer = ((
  options: ClPlugin.FactoryOptions<ClPluginDataLayer.Options>,
) => {
  return (
    inheritOptions: Pick<ClPluginDataLayer.Options, keyof ClPlugin.Options>,
  ) =>
    new ClPluginDataLayer({
      ...(options ?? {}),
      ...inheritOptions,
    });
}) satisfies ClPlugin.Factory;

export { ClPluginDataLayer, clPluginDataLayer };
